import { MenuItem } from "@/models/MenuItem";

export const footerMenuItems: Array<MenuItem> = [
    {
        name: "About Us",
        href: "https://espired.com/about-us"
    },
    {
        name: "Our Lessons",
        href: "https://espired.com/products"
    },
    {
        name: "Contact Us",
        href: "https://espired.com/contact"
    },
    {
        name: "Terms of Use",
        href: "https://espired.com/terms-of-use"
    },
    {
        name: "Privacy Policy",
        href: "https://espired.com/privacy-policy"
    }
];
