import { App } from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faAngleDown, faAngleRight, faArrowCircleLeft, faArrowCircleRight, faArrowRight, faAward, faBan,
    faBars, faBook, faBookOpen, faBookReader, faCat, faChalkboardTeacher, faChartPie, faCheckCircle,
    faCircle, faClipboard, faClosedCaptioning, faCloud, faCloudSun, faCog, faEdit,
    faEnvelopeOpenText, faExclamationTriangle, faExpand, faGamepad, faHome, faInfoCircle, faLock,
    faLockOpen, faMapMarked, faMinusCircle, faMountain, faPencilRuler, faPlay, faPlus, faPlusCircle,
    faQuestion, faRedo, faSave, faShoppingBasket, faSignInAlt, faSignOutAlt, faTimesCircle,
    faTrophy, faUser, faUserGraduate, faUsers
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export function useFontAwesome(app: App): App {
    library.add(
        faSignInAlt,
        faSignOutAlt,
        faBars,
        faAngleRight,
        faAngleDown,
        faArrowCircleRight,
        faArrowRight,
        faCircle,
        faClipboard,
        faBookReader,
        faBookOpen,
        faGamepad,
        faChartPie,
        faAward,
        faUser,
        faUsers,
        faLock,
        faPlus,
        faTrophy,
        faPlay,
        faPencilRuler,
        faBook,
        faChalkboardTeacher,
        faTimesCircle,
        faCheckCircle,
        faRedo,
        faQuestion,
        faCat,
        faQuestion,
        faMapMarked,
        faEnvelopeOpenText,
        faEdit,
        faBan,
        faSave,
        faInfoCircle,
        faHome,
        faMountain,
        faCloud,
        faCloudSun,
        faUserGraduate,
        faShoppingBasket,
        faCog,
        faLockOpen,
        faArrowCircleLeft,
        faPlusCircle,
        faMinusCircle,
        faExpand,
        faClosedCaptioning,
        faTimesCircle,
        faExclamationTriangle
    );

    return app.component("font-awesome-icon", FontAwesomeIcon);
}
