import { ProfileModel, ThemeEnum, UserModel } from "@/models";

export function mapProfile(
    user: UserModel | null,
    profile: ProfileModel,
    defaultTheme: ThemeEnum = "Sky"
): ProfileModel {
    profile.firstName = user?.firstName || "";
    profile.lastName = user?.lastName || "";
    profile.name = user?.name || "";
    profile.email = user?.email || "";
    profile.profileImageId = user?.profileImageId || null;
    profile.profileImageUrl = user?.profileImageUrl ?? null;
    profile.theme = user?.theme || defaultTheme;

    return profile;
}
