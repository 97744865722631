import { RouteLocationRaw, RouteRecordRaw } from "vue-router";

import { Breadcrumb } from "@/models";

/**
 * Maps a simple route to a breadcrumb. Used for routes that do not have dynamic text.
 * @param route the route
 * @returns a breadcrumb representing the given route
 */
export function mapRouteBreadcrumb(route: RouteRecordRaw): Breadcrumb {
    return {
        routeName: route.name as string,
        getLocation: (): RouteLocationRaw => ({ name: route.name }),
        getText: (): string => route.meta?.title as string
    };
}
