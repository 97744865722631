
import { AppMutationTypes, useStore } from "@/store";
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import { Breadcrumb } from "@/models";

export default defineComponent({
    setup() {
        const store = useStore();
        const { state, getters } = store;
        const route = useRoute();

        // Toggle sidebar collapse
        const sidebarIsCollapsed = computed(() => state.sidebarIsCollapsed);

        const handleToggle = (): void => {
            store.commit(
                AppMutationTypes.setSidebarStatus,
                !sidebarIsCollapsed.value
            );
        };

        const getBreadcrumbText = (
            breadcrumb: Breadcrumb,
            routeParams: Record<string, string>
        ): string => {
            return breadcrumb.getText(routeParams, store);
        };

        return {
            assetBaseUrl: state.cdnBaseUrl + "/assets/images",
            title: computed(() => state.pageTitle || route.name?.toString()),
            imageUrl: computed(() => state.pageImageUrl),
            routeParams: computed(() => route.params as Record<string, string>),
            breadcrumbs: computed(() => {
                const breadcrumbs = (route.meta.breadcrumbs ||
                    []) as unknown as Breadcrumb[];

                return breadcrumbs.length > 0 ? breadcrumbs : null;
            }),
            signedIn: computed(() => getters.signedIn),
            handleToggle,
            getBreadcrumbText
        };
    }
});
