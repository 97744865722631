import creditCardType, { types as CardType } from "credit-card-type";

export type AdvantagePaymentTypeCodes = "VS" | "AX" | "DC" | "MC";

export function getPaymentTypeCode(
    cardNumber: string
): AdvantagePaymentTypeCodes | null {
    const type = creditCardType(cardNumber);

    if (type && type[0] && type[0].type) {
        switch (type[0].type) {
            case CardType.AMERICAN_EXPRESS:
                return "AX";
            case CardType.VISA:
                return "VS";
            case CardType.MASTERCARD:
                return "MC";
            case CardType.DISCOVER:
                return "DC";
        }
    }

    return null;
}
