
import { defineComponent } from "vue";
import { useStore } from "@/store";
import { footerMenuItems } from "./footer-menu";

export default defineComponent({
    setup() {
        const { state } = useStore();
        return { menuItems: footerMenuItems, version: state.version };
    }
});
