import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d797d69c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "alert-heading font-weight-bold"
}
const _hoisted_2 = {
  key: 0,
  class: "mb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`alert alert-${_ctx.type}`),
    role: "alert"
  }, [
    _renderSlot(_ctx.$slots, "header", {}, () => [
      (_ctx.header)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(_ctx.header), 1))
        : _createCommentVNode("", true)
    ], true),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      (_ctx.message)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.message), 1))
        : _createCommentVNode("", true)
    ], true)
  ], 2))
}