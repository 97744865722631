import {
    AAD_API_SCOPES, AAD_EDIT_PROFILE_AUTHORITY, AAD_PARENT_SIGN_IN_AUTHORITY,
    AAD_RESET_PASSWORD_AUTHORITY, AAD_STUDENT_SIGN_IN_AUTHORITY
} from "@/configuration";
import { Policy, PolicyConfiguration } from "@elite/msal";

export const studentSignUpSignInPolicy: Policy = {
    authority: AAD_STUDENT_SIGN_IN_AUTHORITY,
    scopes: AAD_API_SCOPES
};

export const parentSignUpSignInPolicy: Policy = {
    authority: AAD_PARENT_SIGN_IN_AUTHORITY,
    scopes: AAD_API_SCOPES
};

const editProfile: Policy = {
    authority: AAD_EDIT_PROFILE_AUTHORITY,
    scopes: AAD_API_SCOPES
};

const resetPassword: Policy = {
    authority: AAD_RESET_PASSWORD_AUTHORITY,
    scopes: AAD_API_SCOPES
};

export const policyConfig: PolicyConfiguration = {
    signUpSignIn: studentSignUpSignInPolicy,
    editProfile,
    resetPassword
};
