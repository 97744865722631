import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.profileImageSource)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        id: "avatar",
        src: _ctx.profileImageSource,
        alt: `${_ctx.fullName} Profile Image`
      }, null, 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("img", {
        key: 1,
        id: "avatar",
        src: `${_ctx.assetBaseUrl}/default-profile-image.png`,
        alt: "Default Profile Image"
      }, null, 8, _hoisted_2))
}