import { USPS_USER_ID } from "@/configuration";
import { AddressModel } from "@/models";
import { Address, AddressValidationClient, AddressValidationClientError } from "@elite/usps-client";

const userId: string = USPS_USER_ID;
export const client = new AddressValidationClient();

/**
 * Validates an address using the USPS API, updates the address, and returns true if valid.
 * If invalid, returns false and does not update the address.
 * If a network error occurs, logs a warning and returns true.
 * @param address the address to validate
 * @returns true if valid, false if invalid
 */
export async function validateAddress(address: AddressModel): Promise<boolean> {
    const uspsAddress: Address = {
        // These are swapped between line 2 and 1 --on purpose--.
        // USPS seems to return what we think of "line 1" as address2
        address2: address.line1 || undefined,
        address1: address.line2 || undefined,
        city: address.city || undefined,
        state: address.stateCode || undefined,
        zip5: address.postalCode || undefined
    };

    try {
        const validatedAddress = await client.validate(userId, uspsAddress);

        address.line1 = validatedAddress.address2;
        address.line2 = validatedAddress.address1;
        address.city = validatedAddress.city;
        address.stateCode = validatedAddress.state;
        address.postalCode = validatedAddress.zip5;

        return true;
    } catch (e) {
        // If the request failed (e.g. the API SSL cert expired), don't treat it as invalid
        const uspsError = e as AddressValidationClientError;
        const error = e as Error;

        if (
            (uspsError && uspsError.description === "Failed") ||
            (error && error.message === "Network Error")
        ) {
            // eslint-disable-next-line no-console
            console.warn("USPS API request failed", uspsError);
            return true;
        }

        return false;
    }
}
