import { MutationTree } from "vuex";

import { AppMutations } from "./AppMutations";
import { AppState } from "./AppState";

export const AppMutationTypes = {
    /**
     * Casting to "const" to avoid TS error in AppMutations:
     * "A computed property name in an interface must refer to an expression whose type is a literal type or a 'unique symbol' type.ts(1169)"
     */
    setUser: "setUser" as const,
    updateProfile: "updateProfile" as const,
    updateUserTheme: "updateUserTheme" as const,

    setAccessToken: "setAccessToken" as const,
    setSettings: "setSettings" as const,

    setStickers: "setStickers" as const,
    setCourseStickers: "setCourseStickers" as const,

    setRank: "setRank" as const,

    setPageTitle: "setPageTitle" as const,
    setPageImage: "setPageImage" as const,

    queueLoading: "queueLoading" as const,
    dequeueLoading: "dequeueLoading" as const,

    setError: "setError" as const,

    addLessonProgress: "addLessonProgress" as const,

    updateCurrentStudentVideoProgress:
        "updateCurrentStudentVideoProgress" as const,
    updateCurrentStudentDigitalBookProgress:
        "updateCurrentStudentDigitalBookProgress" as const,
    updateCurrentStudentQuizProgress:
        "updateCurrentStudentQuizProgress" as const,

    setStudents: "setStudents" as const,
    createStudent: "createStudent" as const,
    updateStudent: "updateStudent" as const,
    removeStudent: "removeStudent" as const,

    setRecentCourses: "setRecentCourses" as const,

    setCourseItems: "setCourseItems" as const,
    setLessonItems: "setLessonItems" as const,
    addLesson: "addLesson" as const,
    setSubscriptions: "setSubscriptions" as const,
    setSubscriptionDetail: "setSubscriptionDetail" as const,
    removeSubscriptionDetail: "removeSubscriptionDetail" as const,

    setCommunityImages: "setCommunityImages" as const,

    setSidebarStatus: "setSidebarStatus" as const
};

export const mutations: MutationTree<AppState> & AppMutations = {
    [AppMutationTypes.setAccessToken](state, accessToken) {
        state.accessToken = accessToken;
    },

    [AppMutationTypes.setUser](state, user) {
        state.user = user;
    },

    [AppMutationTypes.updateUserTheme](state, theme) {
        if (state.user) {
            state.user.theme = theme;
        }
    },

    [AppMutationTypes.updateProfile](state, profile) {
        state.user = {
            ...state.user,
            email: profile.email,
            name: profile.name,
            firstName: profile.firstName,
            lastName: profile.lastName,
            profileImageId: profile.profileImageId,
            profileImageUrl: profile.profileImageUrl
        };
    },

    [AppMutationTypes.setStickers](state, stickers) {
        state.stickers = stickers;
    },

    [AppMutationTypes.setCourseStickers](state, courseStickers) {
        state.courseStickers = courseStickers;
    },

    [AppMutationTypes.setRank](state, rank) {
        state.rank = rank;
    },

    [AppMutationTypes.setRecentCourses](state, courses) {
        state.recentCourses = courses;
    },

    [AppMutationTypes.setSettings](state, settings) {
        state.settings = settings;
    },

    [AppMutationTypes.setPageTitle](state, title) {
        state.pageTitle = title;
    },

    [AppMutationTypes.setPageImage](state, pageImageUrl) {
        state.pageImageUrl = pageImageUrl;
    },

    [AppMutationTypes.queueLoading](state) {
        state.loadingQueue.push(true);
    },

    [AppMutationTypes.dequeueLoading](state) {
        state.loadingQueue.pop();
    },

    [AppMutationTypes.setError](state, error) {
        state.error = error;
    },

    [AppMutationTypes.setStudents](state, students) {
        state.students = students;
    },

    [AppMutationTypes.updateCurrentStudentVideoProgress](state, payload) {
        const { lessonId, videoProgress } = payload;

        const lesson = state.lessons?.find((l) => l.id === lessonId);

        if (lesson && lesson.lessonProgress) {
            lesson.lessonProgress.videoProgress = videoProgress;
        }
    },

    [AppMutationTypes.updateCurrentStudentDigitalBookProgress](state, payload) {
        const { lessonId, digitalBookProgress } = payload;

        const lesson = state.lessons?.find((l) => l.id === lessonId);

        if (lesson && lesson.lessonProgress) {
            lesson.lessonProgress.digitalBookProgress = digitalBookProgress;
        }
    },

    [AppMutationTypes.updateCurrentStudentQuizProgress](state, payload) {
        const { lessonId, quizId, quizProgress } = payload;
        const lesson = state.lessons?.find((l) => l.id === lessonId);

        if (lesson && lesson.lessonProgress?.quizAttemptProgresses) {
            // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findIndex
            const index = lesson.lessonProgress.quizAttemptProgresses.findIndex(
                (q) => q.quizId === quizId
            );

            // Add score for quiz without progress
            if (index === -1) {
                lesson.lessonProgress.quizAttemptProgresses?.push(quizProgress);
                return;
            }

            // Update existing progress for quiz
            lesson.lessonProgress.quizAttemptProgresses[index] = quizProgress;
        }
    },

    [AppMutationTypes.createStudent](state, student) {
        state.students.push(student);
    },

    [AppMutationTypes.updateStudent](state, student) {
        const index = state.students.findIndex((s) => s.id === student.id);
        state.students[index] = student;
    },

    [AppMutationTypes.removeStudent](state, student) {
        state.students = state.students.filter((s) => s.id !== student.id);
    },

    [AppMutationTypes.setCourseItems](state, items) {
        // clear the array
        state.courseItems.splice(0, state.courseItems.length);
        // add the new items
        state.courseItems.push(...items);
    },

    [AppMutationTypes.setLessonItems](state, items) {
        // clear the array
        state.lessonItems.splice(0, state.lessonItems.length);
        // add the new items
        state.lessonItems.push(...items);
    },

    [AppMutationTypes.addLesson](state, lesson) {
        state.lessons.push(lesson);
    },

    [AppMutationTypes.setSubscriptions](state, subscriptions) {
        state.subscriptions = subscriptions;
    },

    [AppMutationTypes.setSubscriptionDetail](state, { id, subscription }) {
        state.subscriptionDetails[id] = subscription;
    },

    [AppMutationTypes.removeSubscriptionDetail](state, { id }) {
        delete state.subscriptionDetails[id];
    },

    [AppMutationTypes.setCommunityImages](state, images) {
        state.communityImages = images;
    },

    [AppMutationTypes.setSidebarStatus](state, isOpen) {
        state.sidebarIsCollapsed = isOpen;
    }
};
