import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["btn", { 'btn-secondary': _ctx.isCancelPopUp, 'btn-gray btn-gray-outline': !_ctx.isCancelPopUp }])
  }, [
    _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          icon: _ctx.icon
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true)
  ], 2))
}