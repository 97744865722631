
import { useStore } from "@/store";
import { defineComponent, computed } from "vue";

export default defineComponent({
    props: {
        profileImageSource: {
            type: String,
            default: null
        }
    },
    setup() {
        const { getters, state } = useStore();
        const assetBaseUrl = state.cdnBaseUrl + "/assets/images";
        return {
            assetBaseUrl,
            fullName: computed(() => getters.fullName)
        };
    }
});
