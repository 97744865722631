// app constants
export const BASE_URL: string = process.env.BASE_URL;
export const API_BASE_URL: string = process.env.VUE_APP_API_BASE_URL;
export const APP_VERSION: string = process.env.VUE_APP_VERSION || "1.0.0";
export const CDN_BASE_URL: string = process.env.VUE_APP_CDN_URL;

// google analytics
export const GA_ID: string = process.env.VUE_APP_GA_ID;

// AAD B2C
export const AAD_PARENT_SIGN_IN_AUTHORITY: string =
    process.env.VUE_APP_AAD_PARENT_SIGN_IN_AUTHORITY;
export const AAD_STUDENT_SIGN_IN_AUTHORITY: string =
    process.env.VUE_APP_AAD_STUDENT_SIGN_IN_AUTHORITY;
export const AAD_RESET_PASSWORD_AUTHORITY: string =
    process.env.VUE_APP_AAD_RESET_PASSWORD_AUTHORITY;
export const AAD_EDIT_PROFILE_AUTHORITY: string =
    process.env.VUE_APP_AAD_EDIT_PROFILE_AUTHORITY;
export const AAD_CLIENT_ID: string = process.env.VUE_APP_AAD_CLIENT_ID;
export const AAD_API_SCOPES: string[] = (
    process.env.VUE_APP_API_SCOPES || ""
).split(" ");
export const AAD_REDIRECT_URI: string = process.env.VUE_APP_AAD_REDIRECT_URI;
export const AAD_KNOWN_AUTHORITIES: string[] = [
    AAD_PARENT_SIGN_IN_AUTHORITY,
    AAD_STUDENT_SIGN_IN_AUTHORITY
];
export const AAD_LOGOUT_REDIRECT_URI: string =
    process.env.VUE_APP_AAD_LOGOUT_REDIRECT_URI;
export const AAD_CACHE_LOCATION: string =
    process.env.VUE_APP_AAD_CACHE_LOCATION;

// USPS
export const USPS_USER_ID: string = process.env.VUE_APP_USPS_USERID;

// Brightcove
export const BRIGHTCOVE_ACCOUNT_ID: string =
    process.env.VUE_APP_BRIGHTCOVE_ACCOUNT_ID;
export const BRIGHTCOVE_PLAYER_ID: string =
    process.env.VUE_APP_BRIGHTCOVE_PLAYER_ID;
