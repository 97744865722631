import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "top-navigation",
  class: "navbar navbar-light border-bottom justify-content-between"
}
const _hoisted_2 = {
  id: "page-info",
  class: "d-flex align-items-center"
}
const _hoisted_3 = {
  href: "#",
  "data-bs-toggle": "offcanvas",
  "data-bs-target": "#offcanvas-top",
  class: "d-md-none d-inline",
  role: "button",
  "aria-label": "Toggle navigation"
}
const _hoisted_4 = {
  key: 0,
  class: "d-none d-md-flex flex-column"
}
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "d-flex flex-column" }
const _hoisted_7 = {
  key: 0,
  class: "d-md-none flex-column"
}
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = {
  key: 1,
  id: "breadcrumb",
  class: "align-items-center d-flex flex-wrap"
}
const _hoisted_10 = {
  class: "mb-0 me-2 text-black active",
  href: "#"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", _hoisted_3, [
        _createVNode(_component_font_awesome_icon, {
          id: "sidebarToggle",
          icon: "bars",
          size: "2x",
          class: "text-primary me-4"
        })
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "d-none d-md-inline",
        "aria-label": "Toggle navigation",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleToggle()))
      }, [
        _createVNode(_component_font_awesome_icon, {
          id: "sidebarToggle",
          icon: "bars",
          size: "2x",
          class: "text-primary me-4",
          role: "button"
        })
      ]),
      (_ctx.imageUrl)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("img", {
              src: _ctx.imageUrl,
              class: "breadcrumb-image me-3",
              alt: `${_ctx.title} Icon Image`
            }, null, 8, _hoisted_5)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("h1", {
          id: "page-title",
          class: _normalizeClass(["mb-0 pb-0", { 'course-image-present': _ctx.imageUrl }])
        }, _toDisplayString(_ctx.signedIn ? _ctx.title : "eSpired"), 3),
        (_ctx.imageUrl)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("img", {
                src: _ctx.imageUrl,
                class: "breadcrumb-image me-3",
                alt: `${_ctx.title} Icon Image`
              }, null, 8, _hoisted_8)
            ]))
          : _createCommentVNode("", true),
        (_ctx.signedIn && _ctx.breadcrumbs)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (breadcrumb, index) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  key: `breadcrumb-${index}`,
                  to: breadcrumb.getLocation(_ctx.routeParams),
                  class: _normalizeClass([
                            'mb-0 me-2 text-black',
                            {
                                active: index === _ctx.breadcrumbs.length
                            }
                        ])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getBreadcrumbText(breadcrumb, _ctx.routeParams)) + " ", 1),
                    _createVNode(_component_font_awesome_icon, { icon: "angle-right" })
                  ]),
                  _: 2
                }, 1032, ["to", "class"]))
              }), 128)),
              _createElementVNode("a", _hoisted_10, _toDisplayString(_ctx.title), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("img", {
      id: "nav-logo",
      class: "d-none d-md-inline",
      alt: "eSpired Logo",
      src: `${_ctx.assetBaseUrl}/logo-color.png`
    }, null, 8, _hoisted_11),
    _createElementVNode("img", {
      id: "nav-logo-mobile",
      class: "d-md-none d-inline",
      alt: "eSpired Logo",
      src: `${_ctx.assetBaseUrl}/logo-color-icon.png`
    }, null, 8, _hoisted_12)
  ]))
}