/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
// see https://getbootstrap.com/docs/5.1/getting-started/webpack/#importing-javascript

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { createApp } from "vue";
import VueGtag, { PageView } from "vue-gtag";
import { RouteRecordRaw } from "vue-router";

import App from "@/App.vue";
import { msalService } from "@/authentication";
import { addSharedComponents } from "@/components";
import { GA_ID } from "@/configuration";
import { router } from "@/router";
import { store, storeKey } from "@/store";
import { configureValidation } from "@/validation";
import { errorLog } from "@elite/vue-logging";
import { msal } from "@elite/vue-msal";

configureValidation();

const app = createApp(App);

addSharedComponents(app);

//Configuration for Google Analytics VueGtag plugin
const gtagOptions = {
    pageTrackerTemplate(route: RouteRecordRaw): PageView {
        const title = route.name?.toString() ?? "";
        return {
            //Send the Route Name and Path to Google Analytics
            page_title: title,
            page_path: route.path
        };
    },
    config: { id: GA_ID }
};

app.use(store, storeKey)
    .use(router)
    .use(VueGtag, gtagOptions, router)
    .use(errorLog)
    .use(msal, msalService)
    .mount("#app");
