import { CDN_BASE_URL } from "@/configuration";
import { MenuGroup } from "@/models";
import { MenuItem } from "@/models/MenuItem";
import { routeNames } from "@/router/routeNames";

const cndBaseUrl = CDN_BASE_URL;

export const library: MenuItem = {
    name: routeNames.library,
    icon: "book",
    external: true,
    href: "https://library.espired.com/",
    tileItem: {
        image: `${cndBaseUrl}/assets/images/parent-headers/library_2022-05-19.png`,
        description:
            "Borrow and enjoy digital content of all types (eBooks, eAudiobooks, eVideos and eMusic) anywhere, anytime, from your computer, tablet, eReader, or smartphone.",
        borderColorHex: "#7a60c4",
        linkText: "Purchase Access To Library",
        sortOrder: 4
    },
    group: MenuGroup.ESpired,
    isLibrary: true
};

export const menuItems: Array<MenuItem> = [
    {
        name: "Marketplace",
        external: true,
        href: "https://www.homeschoolbuyersco-op.org/",
        forParent: false,
        forStudent: false,
        tileItem: {
            image: `${cndBaseUrl}/assets/images/parent-headers/marketplace_2022-05-23.png`,
            description:
                "An encompassing one-stop shop for energizing curriculum and exclusive, educational resources.",
            borderColorHex: "#2698bd",
            linkText: "Visit Homeschool Buyers Club",
            sortOrder: 3
        }
    },
    {
        name: routeNames.home,
        route: { name: routeNames.home },
        icon: "home",
        group: MenuGroup.Home
    },
    {
        name: routeNames.courses,
        route: { name: routeNames.courses },
        icon: "map-marked",
        tileItem: {
            image: `${cndBaseUrl}/assets/images/parent-headers/learning-quests-2-1776.png`,
            description:
                "Manage curriculum, explore learning adventures, find entertaining videos and exciting educational activities.",
            borderColorHex: "#5c9b37",
            linkText: "Start Learning",
            sortOrder: 0
        },
        group: MenuGroup.Courses
    },
    library,
    {
        name: routeNames.reportCards,
        route: { name: routeNames.reportCards },
        icon: "user-graduate",
        forParent: true,
        tileItem: {
            image: `${cndBaseUrl}/assets/images/parent-headers/report-cards.png`,
            description:
                "Track and evaluate your child's education across products and activities, check on awards and badges, participate in their journey and watch them excel.",
            borderColorHex: "#d96c12",
            linkText: "View Report Cards",
            sortOrder: 1
        },
        group: MenuGroup.Courses
    },
    {
        name: routeNames.students,
        route: { name: routeNames.students },
        icon: "users",
        forParent: true,
        tileItem: {
            image: `${cndBaseUrl}/assets/images/parent-headers/my-students.png`,
            description:
                "Set up and manage accounts for your kids and homeschoolers. Equip and empower them for success.",
            borderColorHex: "#b68a05",
            linkText: "Manage Your Students",
            sortOrder: 2
        },
        group: MenuGroup.Account
    },
    {
        name: routeNames.account,
        route: { name: routeNames.account },
        icon: "shopping-basket",
        forParent: true,
        group: MenuGroup.Account
    },
    {
        name: routeNames.community,
        route: { name: routeNames.community },
        icon: "envelope-open-text",
        forParent: true,
        tileItem: {
            image: `${cndBaseUrl}/assets/images/parent-headers/community.png`,
            description:
                "Engage with our rapidly expanding community, submit your child's work for EverBright Kids Magazine, share your experiences and get expert support.",
            borderColorHex: "#406ab3",
            linkText: "Visit The Community",
            sortOrder: 5
        },
        group: MenuGroup.ESpired
    },
    // eSpired will want this shown again at point in the future
    // {
    //     name: routeNames.faq,
    //     route: { name: routeNames.faq },
    //     icon: "question",
    //     forParent: true,
    //     group: MenuGroup.Information
    // },
    {
        name: routeNames.collection,
        route: { name: routeNames.collection },
        icon: "trophy",
        forStudent: true,
        group: MenuGroup.Courses
    },
    {
        name: routeNames.profile,
        route: { name: routeNames.profile },
        icon: "cog",
        group: MenuGroup.Account
    }
];
