import parsePhoneNumber from "libphonenumber-js";

/**
 * Formats a US phone number
 * @param value
 * @returns a formatted phone number or an empty string
 */
export function formatPhoneNumber(
    value: string | undefined | null
): string | undefined | null {
    // see https://github.com/catamphetamine/libphonenumber-js
    if (value) {
        const parsed = parsePhoneNumber(value, {
            defaultCountry: "US",
            defaultCallingCode: "1"
        });

        if (parsed) {
            return parsed.formatNational();
        }
    }

    return value;
}
