import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navigation_link_content = _resolveComponent("navigation-link-content")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "list-group-item list-group-item-action list-group-item-transparent",
    to: _ctx.menuItem.route
  }, {
    default: _withCtx(() => [
      _createVNode(_component_navigation_link_content, { "menu-item": _ctx.menuItem }, null, 8, ["menu-item"])
    ]),
    _: 1
  }, 8, ["to"]))
}