export const routePaths = {
    reportCards: "/report-cards",
    courses: "/learning-quest",
    course: "/learning-quest/:courseId",
    lesson: "/learning-quest/:courseId/lesson/:lessonId",
    quiz: "/learning-quest/:courseId/lesson/:lessonId/quiz/:id",
    students: "/students",
    faq: "/faq",
    profile: "/profile",
    account: "/account",
    subscription: "/subscription/:type/:subscriptionId",
    collection: "/collection",
    community: "/community",
    cancellationOffer: "/subscription/:type/:subscriptionId/cancellation-offer"
};
