
import { defineComponent } from "vue";

export default defineComponent({
    name: "edit-button",
    props: {
        text: {
            type: String,
            required: false,
            default: "Edit"
        },
        icon: {
            type: String,
            required: false,
            default: "edit"
        }
    }
});
