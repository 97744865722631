import { GetterTree } from "vuex";

import {
    parentSignUpSignInPolicy,
    studentSignUpSignInPolicy
} from "@/authentication";
import {
    CourseItemModel,
    LessonItemModel,
    LessonModel,
    StickerModel
} from "@/models";
import { ModelValidationRules } from "@elite/validation";

import { AppGetters } from "./AppGetters";
import { AppState } from "./AppState";

export const getters: GetterTree<AppState, AppState> & AppGetters = {
    userName: (state) => state.user?.name ?? "",

    fullName: (state) => {
        const firstName = state.user?.firstName || "";
        const lastName = state.user?.lastName || "";
        return `${firstName} ${lastName}`;
    },

    isParent: (state) => state.user?.role === "Parent" ?? false,
    isStudent: (state) => state.user?.role === "Student" ?? false,

    isAuthorized: (state) =>
        state.user?.role === "Student" || state.user?.role === "Parent",

    signedIn: (state) =>
        state.accessToken !== null && state.accessToken !== undefined,

    loading: (state) => state.loadingQueue.length > 0,

    signInPolicy: (state) =>
        state.user?.role === "Student"
            ? studentSignUpSignInPolicy
            : parentSignUpSignInPolicy,

    getModelValidationRules:
        (state) =>
        (modelName): ModelValidationRules =>
            state.settings?.validationRules &&
            state.settings.validationRules[modelName]
                ? (state.settings.validationRules[
                      modelName
                  ] as ModelValidationRules)
                : {},

    getCourseSticker:
        (state) =>
        (lessonId: number): StickerModel | null =>
            state.courseStickers.find((s) => s.lessonId === lessonId) ?? null,

    getCourseItem:
        (state) =>
        (courseId): CourseItemModel | null =>
            state.courseItems.find((c) => c.id === courseId) ?? null,

    getLessonItems:
        (state) =>
        (courseId): Array<LessonItemModel> | null =>
            state.lessonItems.filter((x) => x.courseId === courseId) ?? null,

    getLessonItem:
        (state) =>
        (lessonId): LessonItemModel | null =>
            state.lessonItems.find((l) => l.id === lessonId) ?? null,

    getLesson:
        (state) =>
        (lessonId): LessonModel | null =>
            state.lessons.find((l) => l.id === lessonId) ?? null
};
