
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        header: {
            type: String,
            default: ""
        },
        message: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: "success"
        }
    }
});
