
import "@progress/kendo-theme-bootstrap/dist/all.css";

import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { setRaygunUser } from "@elite/vue-logging";
import { VOffline } from "v-offline";
import { useStore } from "@/store";
import { ThemeEnum, UserModel } from "@/models";

import PageContentNavigationVue from "@/components/layout/PageContentNavigation.vue";
import PageContentFooterVue from "@/components/layout/PageContentFooter.vue";
import SidebarNavigationVue from "@/components/layout/SidebarNavigation.vue";
import MobileOffCanvasNavigationVue from "@/components/layout/MobileOffCanvasNavigation.vue";
import SignInVue from "@/components/SignIn.vue";
import LoadingIndicator from "./components/LoadingIndicator.vue";
import BannerMessage from "./components/BannerMessage.vue";

export default defineComponent({
    components: {
        "sidebar-navigation": SidebarNavigationVue,
        "page-content-navigation": PageContentNavigationVue,
        "mobile-off-canvas-navigation": MobileOffCanvasNavigationVue,
        "page-content-footer": PageContentFooterVue,
        "sign-in": SignInVue,
        "loading-indicator": LoadingIndicator,
        "v-offline": VOffline,
        "banner-message": BannerMessage
    },
    setup() {
        const defaultTheme: ThemeEnum = "Sky";

        const { state, getters } = useStore();

        // this is intentionally not getters.loading since it's specific to loading the app, not ajax requests
        const appLoading = ref(true);

        // Show a banner when the browser is offline
        // see https://github.com/vinayakkulkarni/v-offline
        const online = ref(true);
        const onNetworkChange = (status: boolean): void => {
            online.value = status;
        };

        onMounted(() => {
            appLoading.value = false;
        });

        // tell raygun about the logged in user, once they are logged in
        watch(
            () => state.user,
            (user: UserModel | undefined) => {
                if (user && user.id) {
                    const userInfo = {
                        identifier: user.id.toString(),
                        isAnonymous: false,
                        email: user.email,
                        firstName: user.firstName || user.name,
                        fullName:
                            (user.firstName || user.name || user.email || "") +
                            " " +
                            (user.lastName || user.familyName || "")
                    };
                    setRaygunUser(userInfo);
                }
            },
            { immediate: true }
        );

        return {
            appLoading,
            sidebarIsCollapsed: computed(() => state.sidebarIsCollapsed),
            signedIn: computed(() => getters.signedIn),
            theme: computed(() => state.user?.theme || defaultTheme),
            online,
            onNetworkChange
        };
    }
});
