
import { defineComponent } from "vue";
import { CDN_BASE_URL } from "@/configuration";

export default defineComponent({
    name: "logo-footer",
    setup() {
        const logos = [
            {
                title: "Learn Our History Logo",
                link: "https://learnourhistory.com/",
                source: "learn-our-history-logo.png"
            },
            {
                title: "Family Bible Films Logo",
                link: "https://familybiblefilms.com/",
                source: "family-bible-films-logo.png"
            },
            {
                title: "Money Bright Kids Logo",
                link: "https://moneybrightkids.com/",
                source: "moneybright-kids-logo.png"
            },
            {
                title: "Everbright News Logo",
                link: "https://explore.everbrightkids.com/",
                source: "everbright-news-logo.png"
            },
            {
                title: "Rules of the Road Logo",
                link: "http://rulesoftheroadforkids.com/",
                source: "rules-of-the-road-logo.png"
            },
            {
                title: "The Kids Guide Logo",
                link: "https://thekidsguide.com/",
                source: "the-kids-guide-logo.png"
            },
            {
                title: "Everbright Kids Logo",
                link: "https://explore.everbrightkids.com/",
                source: "everbright-kids-logo.png"
            }
        ];

        return {
            logos,
            imagesUrl: CDN_BASE_URL + "/assets/images/footer-logos/"
        };
    }
});
