import { msalService } from "@/authentication";
import { API_BASE_URL } from "@/configuration";
import { RestClient } from "@elite/restclient";

import { AppActionTypes } from "./actions";
import { AppMutationTypes } from "./mutations";
import { store } from "./store";

export const restClient = new RestClient();
restClient.client.defaults.baseURL = API_BASE_URL;

// handle 401 responses from the API
restClient.client.interceptors.response.use(
    (response) => response,
    async (error) => {
        // https://axios-http.com/docs/interceptors
        if (error.response?.status === 401) {
            // try to refresh the token
            await msalService
                .refreshToken(store.getters.signInPolicy)
                .catch(() => {
                    setAccessToken(null);
                });
        } else {
            return Promise.reject(error);
        }
    }
);

/**
 * Sets the access token in the store and in the axios client authorization header, then gets the logged in user and settings.
 * @param accessToken the access token
 */
export function setAccessToken(accessToken: string | null): void {
    store.commit(AppMutationTypes.setAccessToken, accessToken);

    if (accessToken) {
        restClient.setAuthorizationHeader(accessToken);
        store.dispatch(AppActionTypes.getUser);
        store.dispatch(AppActionTypes.getSettings);
    }
}
