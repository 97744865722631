import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "spinner-border spinner-border-sm",
  role: "status",
  "aria-hidden": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["btn", {
            'btn-gray btn-gray-outline': _ctx.isCancelPopUp,
            'btn-primary': !_ctx.isCancelPopUp
        }]),
    type: "submit",
    disabled: _ctx.submitting
  }, [
    _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
    (_ctx.submitting)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 1,
          icon: _ctx.icon
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}