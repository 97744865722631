
import { defineComponent, PropType } from "vue";
import { MenuItem } from "@/models/MenuItem";
import NavigationLinkContent from "./NavigationLinkContent.vue";

export default defineComponent({
    components: {
        "navigation-link-content": NavigationLinkContent
    },
    props: {
        menuItem: {
            type: Object as PropType<MenuItem>,
            required: true
        },
        isParent: {
            type: Boolean,
            required: true
        }
    }
});
