import { Store } from "vuex";

import { AppState } from "@/store";

/**
 * Calls the given function after we have an access token.
 * @param store the store
 * @param callback the function to call we have an access token
 */
export function loadData(store: Store<AppState>, callback: () => void): void {
    if (store.state.accessToken) {
        callback();
        return;
    }

    // wait for the access token to be set before executing the given callback
    store.watch(
        (state) => state.accessToken,
        (accessToken: string | null) => {
            if (accessToken) {
                callback();
            }
        }
    );
}
