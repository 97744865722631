import {
    createRouter,
    createWebHistory,
    RouteComponent,
    RouteLocationNormalized,
    RouteLocationRaw,
    RouteRecordRaw
} from "vue-router";

import { BASE_URL } from "@/configuration";
import { loadUserDependentData } from "@/helpers";
import { Breadcrumb } from "@/models";
import { AppMutationTypes, store } from "@/store";

import { createRoute } from "./createRoute";
import { mapRouteBreadcrumb } from "./mapRouteBreadcrumb";
import { routeNames } from "./routeNames";
import { routePaths } from "./routePaths";

const homeRoute = createRoute("/", routeNames.home, "HomeView");

const reportCardsRoute = createRoute(
    routePaths.reportCards,
    routeNames.reportCards,
    "ReportCardsView",
    undefined,
    undefined,
    "Parent"
);

const coursesRoute = createRoute(
    routePaths.courses,
    routeNames.courses,
    "CoursesView"
);

const courseRoute = createRoute(
    routePaths.course,
    routeNames.course,
    "CourseView",
    [mapRouteBreadcrumb(coursesRoute)],
    (route): { courseId: number } => ({
        courseId: Number(route.params.courseId)
    })
);

const lessonRoute = createRoute(
    routePaths.lesson,
    routeNames.lesson,
    "LessonView",
    [mapRouteBreadcrumb(coursesRoute), mapCourseRouteBreadcrumb(courseRoute)],
    (
        route: RouteLocationNormalized
    ): { courseId: number; lessonId: number } => ({
        courseId: Number(route.params.courseId),
        lessonId: Number(route.params.lessonId)
    })
);

const quizRoute = createRoute(
    routePaths.quiz,
    routeNames.quiz,
    "QuizView",
    [
        mapRouteBreadcrumb(coursesRoute),
        mapCourseRouteBreadcrumb(courseRoute),
        mapLessonRouteBreadcrumb(lessonRoute)
    ],
    (
        route: RouteLocationNormalized
    ): { courseId: number; lessonId: number; id: number } => ({
        courseId: Number(route.params.courseId),
        lessonId: Number(route.params.lessonId),
        id: Number(route.params.id)
    }),
    "Student"
);

const studentsRoute = createRoute(
    routePaths.students,
    routeNames.students,
    "StudentsView",
    undefined,
    undefined,
    "Parent"
);

const faqRoute = createRoute(
    routePaths.faq,
    routeNames.faq,
    "FrequentlyAskedQuestionsView",
    undefined,
    undefined,
    "Parent"
);

const profileRoute = createRoute(
    routePaths.profile,
    routeNames.profile,
    "ProfileView"
);

const communityRoute = createRoute(
    routePaths.community,
    routeNames.community,
    "CommunityView",
    undefined,
    undefined,
    "Parent"
);

const accountRoute = createRoute(
    routePaths.account,
    routeNames.account,
    "AccountView",
    undefined,
    undefined,
    "Parent"
);

const subscriptionRoute = createRoute(
    routePaths.subscription,
    routeNames.subscription,
    "SubscriptionView",
    [mapRouteBreadcrumb(accountRoute)],
    (route): { type: string; subscriptionId: string } => ({
        type: route.params.type as string,
        subscriptionId: route.params.subscriptionId as string
    }),
    "Parent"
);

const myCollectionRoute = createRoute(
    routePaths.collection,
    routeNames.collection,
    "CollectionView",
    [mapRouteBreadcrumb(homeRoute)],
    undefined,
    "Student"
);

const cancellationOfferRoute = createRoute(
    routePaths.cancellationOffer,
    routeNames.cancellationOffer,
    "CancellationOfferView",
    [mapRouteBreadcrumb(accountRoute), mapRouteBreadcrumb(subscriptionRoute)],
    (route): { type: string; subscriptionId: string } => ({
        type: route.params.type as string,
        subscriptionId: route.params.subscriptionId as string
    }),
    "Parent"
);

/**
 * Gets a course breadcrumb.
 */
function mapCourseRouteBreadcrumb(route: RouteRecordRaw): Breadcrumb {
    return {
        ...mapRouteBreadcrumb(courseRoute),
        // override the breadcrumb text with the course name
        getText: (params, { getters }): string =>
            getters.getCourseItem(Number(params.courseId))?.name ??
            "My Products"
    };
}

/**
 * Gets a lesson breadcrumb.
 */
function mapLessonRouteBreadcrumb(route: RouteRecordRaw): Breadcrumb {
    return {
        ...mapRouteBreadcrumb(lessonRoute),
        // override the breadcrumb text with the lesson name
        getText: (params, { getters }): string =>
            getters.getLessonItem(Number(params.lessonId))?.name ?? "Lesson"
    };
}

const routes: Array<RouteRecordRaw> = [
    reportCardsRoute,
    homeRoute,
    coursesRoute,
    courseRoute,
    lessonRoute,
    quizRoute,
    studentsRoute,
    faqRoute,
    profileRoute,
    myCollectionRoute,
    communityRoute,
    profileRoute,
    accountRoute,
    subscriptionRoute,
    cancellationOfferRoute,
    {
        path: "/error-test",
        component: (): Promise<RouteComponent> =>
            import("@/views/ErrorTest.vue")
    }
];

export const router = createRouter({
    history: createWebHistory(BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition): ScrollOptions {
        // https://router.vuejs.org/guide/advanced/scroll-behavior.html
        return { x: 0, y: 0 } as ScrollOptions;
    }
});

router.beforeEach((to): void | RouteLocationRaw => {
    // if the user doesn't have the required role, send them home
    // see https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
    loadUserDependentData(store, () => {
        if (
            to.meta.role &&
            store.state.user?.role !== to.meta.role &&
            to.name !== routeNames.home
        ) {
            return { name: routeNames.home };
        }
    });
});

router.afterEach((to, from) => {
    // Reset the page title & page image so the default on the route is used.
    store.commit(AppMutationTypes.setPageTitle, null);
    store.commit(AppMutationTypes.setPageImage, null);
});
