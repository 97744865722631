import { AxiosError } from "axios";
import { ActionContext, ActionTree, Commit } from "vuex";

import {
    AddressModel, BannerMessageModel, CommunityImageModel, CommunityModel, LessonItemModel,
    LessonModel, PaymentModel, ProfileModel, QuizModel, RankModel, SettingsModel, StickerModel,
    StudentDigitalBookPageModel, StudentModel, StudentProgressModel, StudentQuizAttemptModel,
    StudentVideoProgressModel, SubscriptionModel, ThemeEnum, UserModel, CancelSaveOrderModel
} from "@/models";
import { RestResponse } from "@elite/restclient";

import { AppActions } from "./AppActions";
import { AppMutations } from "./AppMutations";
import { AppState } from "./AppState";
import { AppMutationTypes } from "./mutations";
import { restClient } from "./restClient";

export const AppActionTypes = {
    getUser: "getUser" as const,
    updateProfile: "updateProfile" as const,
    updateUserTheme: "updateUserTheme" as const,

    getSettings: "getSettings" as const,
    getQuiz: "getQuiz" as const,
    getRank: "getRank" as const,
    getRecentCourses: "getRecentCourses" as const,
    getStickers: "getStickers" as const,
    getCourseStickers: "getCourseStickers" as const,
    getBannerMessage: "getBannerMessage" as const,

    createStudentVideo: "createStudentVideo" as const,
    createStudentDigitalBook: "createStudentDigitalBook" as const,
    createStudentQuiz: "createStudentQuiz" as const,
    getStudents: "getStudents" as const,
    createStudent: "createStudent" as const,
    updateStudent: "updateStudent" as const,
    removeStudent: "removeStudent" as const,

    getLesson: "getLesson" as const,
    getLessonItems: "getLessonItems" as const,
    getCourseItems: "getCourseItems" as const,
    requestCourse: "requestCourse" as const,

    getSubscriptions: "getSubscriptions" as const,
    getSubscriptionDetail: "getSubscriptionDetail" as const,
    getStudentProgress: "getStudentProgress" as const,

    cancelSubscription: "cancelSubscription" as const,
    suspendSubscription: "suspendSubscription" as const,
    updateBillingFrequency: "updateBillingFrequency" as const,
    reactivateSubscription: "reactivateSubscription" as const,
    enableEbkBackIssues: "enableEbkBackIssues" as const,

    updatePaymentMethod: "updatePaymentMethod" as const,
    updateCustomerAddress: "updateCustomerAddress" as const,

    submitCommunityRequest: "submitCommunityRequest" as const,

    getCommunityImages: "getCommunityImages" as const,

    createCancelSaveOrder: "createCancelSaveOrder" as const
};

export type AugmentedActionContext = {
    commit<K extends keyof AppMutations>(
        key: K,
        payload: Parameters<AppMutations[K]>[1]
    ): ReturnType<AppMutations[K]>;
} & Omit<ActionContext<AppState, AppState>, "commit">;

export const actions: ActionTree<AppState, AppState> & AppActions = {
    [AppActionTypes.getUser]: async ({ commit }) => {
        return get<UserModel>("/api/User", commit, AppMutationTypes.setUser);
    },

    [AppActionTypes.updateProfile]: async ({ commit }, payload) => {
        return put<ProfileModel>(
            "/api/User/profile",
            payload,
            commit,
            AppMutationTypes.updateProfile,
            undefined,
            false
        );
    },

    [AppActionTypes.updateUserTheme]: async ({ commit }, theme) => {
        return put<ThemeEnum>(
            `/api/User/theme?theme=${theme}`,
            null,
            commit,
            AppMutationTypes.updateUserTheme,
            undefined,
            true
        );
    },

    [AppActionTypes.getSettings]: async ({ commit }) => {
        return get<SettingsModel>(
            "/api/Setting",
            commit,
            AppMutationTypes.setSettings
        );
    },

    [AppActionTypes.getQuiz]: async ({ commit }, id) => {
        return get<QuizModel>(`/api/Quiz/${id}`, commit);
    },

    [AppActionTypes.getRank]: async ({ commit }) => {
        return get<RankModel>("/api/Rank", commit, AppMutationTypes.setRank);
    },

    [AppActionTypes.getRecentCourses]: async ({ commit }) => {
        return get<number[]>(
            "/api/CourseItem/recent",
            commit,
            AppMutationTypes.setRecentCourses
        );
    },

    [AppActionTypes.getStickers]: async ({ commit }) => {
        return get<StickerModel[]>(
            "/api/Sticker",
            commit,
            AppMutationTypes.setStickers
        );
    },

    [AppActionTypes.getCourseStickers]: async ({ commit }, courseId) => {
        return get<StickerModel[]>(
            `/api/Sticker/${courseId}`,
            commit,
            AppMutationTypes.setCourseStickers,
            undefined,
            true
        );
    },

    [AppActionTypes.getBannerMessage]: async ({ commit }) => {
        return get<BannerMessageModel>("/api/BannerMessage", commit);
    },

    [AppActionTypes.createStudentVideo]: async ({ commit }, payload) => {
        const { courseId, lessonId, model } = payload;

        return post<StudentVideoProgressModel>(
            "/api/StudentVideoProgress",
            model,
            commit,
            AppMutationTypes.updateCurrentStudentVideoProgress,
            (data) => ({
                videoProgress: data,
                courseId: courseId,
                lessonId: lessonId
            }),
            true
        );
    },

    [AppActionTypes.createStudentDigitalBook]: async ({ commit }, payload) => {
        const { courseId, lessonId, model } = payload;

        return post<StudentDigitalBookPageModel>(
            "/api/StudentDigitalBookPage",
            model,
            commit,
            AppMutationTypes.updateCurrentStudentDigitalBookProgress,
            (data) => ({
                digitalBookProgress: data,
                courseId: courseId,
                lessonId: lessonId
            }),
            true
        );
    },

    [AppActionTypes.createStudentQuiz]: async ({ commit }, payload) => {
        const { courseId, lessonId, quizId, model } = payload;

        return post<StudentQuizAttemptModel>(
            "/api/StudentQuizAttempt",
            model,
            commit,
            AppMutationTypes.updateCurrentStudentQuizProgress,
            (data) => ({
                quizProgress: data,
                courseId: courseId,
                lessonId: lessonId,
                quizId: quizId
            }),
            true
        );
    },

    [AppActionTypes.getStudents]: async ({ commit, state }) => {
        if (state.students && state.students.length > 0) {
            return state.students;
        }

        await get<StudentModel[]>(
            "/api/Student",
            commit,
            AppMutationTypes.setStudents
        );

        return state.students;
    },

    [AppActionTypes.createStudent]: async ({ commit }, student) => {
        return post<StudentModel>(
            "/api/Student",
            student,
            commit,
            AppMutationTypes.createStudent,
            undefined,
            false
        );
    },

    [AppActionTypes.updateStudent]: async ({ commit }, student) => {
        return put<StudentModel>(
            "/api/Student",
            student,
            commit,
            AppMutationTypes.updateStudent,
            undefined,
            false
        );
    },

    [AppActionTypes.removeStudent]: async ({ commit }, student) => {
        return destroy<StudentModel>(
            `/api/Student/${student.id}`,
            commit,
            AppMutationTypes.removeStudent
        );
    },

    [AppActionTypes.requestCourse]: async ({ commit, dispatch }, model) => {
        const response = await post<StudentModel>(
            "/api/CourseRequest",
            model,
            commit,
            undefined,
            undefined,
            true
        );

        // re-retrieve the course items
        commit(AppMutationTypes.setCourseItems, []);
        dispatch(AppActionTypes.getCourseItems);

        return response;
    },

    [AppActionTypes.getLesson]: async ({ commit }, lessonId) => {
        const result = await get<LessonModel>(
            `/api/Lesson/${lessonId}`,
            commit,
            AppMutationTypes.addLesson
        );
        return result;
    },

    [AppActionTypes.getLessonItems]: async ({ commit, state }, courseId) => {
        // greater than 1 to account for enabling EBK back issues
        if (
            state.lessonItems.filter((lesson) => lesson.courseId === courseId)
                .length > 1
        ) {
            return state.lessonItems;
        }

        const result = await get<Array<LessonItemModel>>(
            `/api/LessonItem/${courseId}`,
            commit,
            AppMutationTypes.setLessonItems
        );
        return result.data || [];
    },

    [AppActionTypes.getCourseItems]: async ({ commit, state }) => {
        if (state.courseItems.length > 0) {
            return state.courseItems;
        }
        await get<Array<LessonItemModel>>(
            "/api/CourseItem",
            commit,
            AppMutationTypes.setCourseItems
        );
        return state.courseItems;
    },

    [AppActionTypes.getSubscriptions]: async ({ commit, state }) => {
        if (state.subscriptions.length > 0) {
            return state.subscriptions;
        }

        await get<SubscriptionModel[]>(
            "/api/Subscription",
            commit,
            AppMutationTypes.setSubscriptions
        );

        return state.subscriptions;
    },

    [AppActionTypes.enableEbkBackIssues]: async ({ commit, state }) => {
        const result = await post(
            "/api/Subscription/EnableEbkBackIssues",
            undefined,
            commit
        );

        if (
            result.status === 200 &&
            state.user?.hasEnabledEbkBackIssues === false
        ) {
            commit(AppMutationTypes.setUser, {
                ...state.user,
                hasEnabledEbkBackIssues: true
            });
        }

        return result;
    },

    [AppActionTypes.getSubscriptionDetail]: async (
        { commit, state },
        { type, id }
    ) => {
        if (state.subscriptionDetails[id]) {
            return state.subscriptionDetails[id];
        }

        await get<SubscriptionModel>(
            `/api/Subscription/${type}/${id}`,
            commit,
            AppMutationTypes.setSubscriptionDetail,
            (data) => ({ id, subscription: data })
        );

        return state.subscriptionDetails[id];
    },

    [AppActionTypes.getStudentProgress]: async ({ commit }, { id }) => {
        const url = id ? `/api/StudentProgress/${id}` : "/api/StudentProgress";
        const response = await get<StudentProgressModel>(url, commit);

        return response.data || null;
    },

    [AppActionTypes.cancelSubscription]: async ({ commit }, { type, id }) => {
        const response = await put<SubscriptionModel>(
            `/api/Subscription/${type}/${id}/cancel`,
            {},
            commit,
            AppMutationTypes.setSubscriptionDetail,
            (data) => ({ id, subscription: data })
        );

        removeSubscriptionsFromState(commit);

        return response;
    },

    [AppActionTypes.suspendSubscription]: async (
        { commit },
        { type, id, model }
    ) => {
        const response = await put<SubscriptionModel>(
            `/api/Subscription/${type}/${id}/suspend`,
            model,
            commit,
            AppMutationTypes.setSubscriptionDetail,
            (data) => ({ id, subscription: data })
        );

        removeSubscriptionsFromState(commit);

        return response.data || null;
    },

    [AppActionTypes.updateBillingFrequency]: async (
        { commit },
        { id, model }
    ) => {
        const response = await put<SubscriptionModel>(
            `/api/Subscription/BookClub/${id}/billing-frequency`,
            model,
            commit,
            AppMutationTypes.setSubscriptionDetail,
            (data) => ({ id, subscription: data })
        );

        removeSubscriptionsFromState(commit);

        return response.data || null;
    },

    [AppActionTypes.reactivateSubscription]: async (
        { commit },
        { id, type }
    ) => {
        const response = await put<SubscriptionModel>(
            `/api/Subscription/${type}/${id}/reactivate`,
            {},
            commit,
            AppMutationTypes.setSubscriptionDetail,
            (data) => ({ id, subscription: data })
        );

        removeSubscriptionsFromState(commit);

        return response.data || null;
    },

    [AppActionTypes.updatePaymentMethod]: async (
        { commit, dispatch },
        { type, customerNumber, subscriptionId, model }
    ) => {
        const response = await put<PaymentModel>(
            `/api/Subscription/${type}/${subscriptionId}/payment/${customerNumber}`,
            model,
            commit
        );

        if (response.status === 200) {
            removeSubscriptionsFromState(commit, subscriptionId);

            dispatch(AppActionTypes.getSubscriptionDetail, {
                type,
                id: subscriptionId
            });
        }

        return response;
    },

    [AppActionTypes.updateCustomerAddress]: async (
        { commit, dispatch },
        { type, subscriptionId, model }
    ) => {
        const response = await restClient.putJson<AddressModel>(
            "/api/Address",
            model
        );

        if (response.status === 200) {
            removeSubscriptionsFromState(commit, subscriptionId);

            dispatch(AppActionTypes.getSubscriptionDetail, {
                type,
                id: subscriptionId
            });
        }

        return response;
    },

    [AppActionTypes.submitCommunityRequest]: async ({ commit }, model) => {
        return post<CommunityModel>(
            "/api/Community",
            model,
            commit,
            undefined,
            undefined,
            true
        );
    },

    [AppActionTypes.getCommunityImages]: async ({ commit, state }) => {
        if (state.communityImages.length > 0) {
            return state.communityImages;
        }

        await get<Array<CommunityImageModel>>(
            "/api/CommunityImage",
            commit,
            AppMutationTypes.setCommunityImages
        );
        return state.communityImages;
    },

    [AppActionTypes.createCancelSaveOrder]: async ({ commit }, { type, id, model }) => {
        return await post<CancelSaveOrderModel>(
            `/api/Subscription/${type}/${id}/cancelSaveOrder`,
            model,
            commit);
    }
};

/**
 * Removes subscriptions from state
 * @param commit the vuex Commit method
 * @param subscriptionId the subscription detail to remove from state (optional)
 */
function removeSubscriptionsFromState(
    commit: Commit,
    subscriptionId?: string
): void {
    commit(AppMutationTypes.setSubscriptions, []);

    if (subscriptionId) {
        commit(AppMutationTypes.setSubscriptionDetail, {
            id: subscriptionId,
            subscription: null
        });
    }
}

/**
 * Executes a POST action and commits the result, if a mutation type is provided
 * @param url the restClient POST request url to execute
 * @param commit the vuex Commit method
 * @param mutationType the optional mutation type to commit from the response
 * @param mutationPayloadFactory an optional factory to create the mutation payload (defaults to response.data if not provided)
 * @param nonBlockingRequest an optional parameter to specify if the loading queue should not be populated
 * @returns response.data
 */
async function post<Response>(
    url: string,
    data: unknown,
    commit: Commit,
    mutationType?: keyof AppMutations,
    mutationPayloadFactory?: (data: Response | null | undefined) => unknown,
    nonBlockingRequest?: boolean
): Promise<RestResponse<Response>> {
    return execute<Response>(
        () => restClient.postJson<Response>(url, data),
        commit,
        mutationType,
        (data: Response | null | undefined) =>
            mutationPayloadFactory ? mutationPayloadFactory(data) : data,
        nonBlockingRequest
    );
}

/**
 * Executes a PUT action and commits the result, if a mutation type is provided
 * @param url the restClient PUT request url to execute
 * @param commit the vuex Commit method
 * @param mutationType the optional mutation type to commit from the response
 * @param mutationPayloadFactory an optional factory to create the mutation payload (defaults to response.data if not provided)
 * @param nonBlockingRequest an optional parameter to specify if the loading queue should not be populated
 * @returns response.data
 */
async function put<Response>(
    url: string,
    data: unknown,
    commit: Commit,
    mutationType?: keyof AppMutations,
    mutationPayloadFactory?: (data: Response | null | undefined) => unknown,
    nonBlockingRequest?: boolean
): Promise<RestResponse<Response>> {
    return execute<Response>(
        () => restClient.putJson<Response>(url, data),
        commit,
        mutationType,
        (data: Response | null | undefined) =>
            mutationPayloadFactory ? mutationPayloadFactory(data) : data,
        nonBlockingRequest
    );
}

/**
 * Executes a DELETE action and commits the result, if a mutation type is provided
 * @param url the restClient DELETE request url to execute
 * @param commit the vuex Commit method
 * @param mutationType the optional mutation type to commit from the response
 * @param mutationPayloadFactory an optional factory to create the mutation payload (defaults to response.data if not provided)
 * @param nonBlockingRequest an optional parameter to specify if the loading queue should not be populated
 * @returns response.data
 */
async function destroy<Response>(
    url: string,
    commit: Commit,
    mutationType?: keyof AppMutations,
    mutationPayloadFactory?: (data: Response | null | undefined) => unknown,
    nonBlockingRequest?: boolean
): Promise<RestResponse<Response>> {
    return execute<Response>(
        () => restClient.delete<Response>(url),
        commit,
        mutationType,
        (data: Response | null | undefined) =>
            mutationPayloadFactory ? mutationPayloadFactory(data) : data,
        nonBlockingRequest
    );
}

/**
 * Executes a GET action and commits the result, if a mutation type is provided
 * @param url the restClient GET request url to execute
 * @param commit the vuex Commit method
 * @param mutationType the optional mutation type to commit from the response
 * @param mutationPayloadFactory an optional factory to create the mutation payload (defaults to response.data if not provided)
 * @param nonBlockingRequest an optional parameter to specify if the loading queue should not be populated
 * @returns response.data
 */
async function get<Response>(
    url: string,
    commit: Commit,
    mutationType?: keyof AppMutations,
    mutationPayloadFactory?: (data: Response | null | undefined) => unknown,
    nonBlockingRequest?: boolean
): Promise<RestResponse<Response>> {
    return execute<Response>(
        () => restClient.getJson<Response>(url),
        commit,
        mutationType,
        (data: Response | null | undefined) =>
            mutationPayloadFactory ? mutationPayloadFactory(data) : data,
        nonBlockingRequest
    );
}

/**
 * Executes an action and commits the result if a mutation type is provided
 * @param action the restClient request to execute
 * @param commit the vuex Commit method
 * @param mutationType the optional mutation type to commit from the response
 * @param mutationPayloadFactory an optional factory to create the mutation payload (defaults to response.data if not provided)
 * @param nonBlockingRequest an optional parameter to specify if the loading queue should not be populated
 * @returns response
 */
async function execute<Response = unknown>(
    action: () => Promise<RestResponse<Response>>,
    commit: Commit,
    mutationType?: keyof AppMutations,
    mutationPayloadFactory?: (data: Response | null | undefined) => unknown,
    nonBlockingRequest?: boolean
): Promise<RestResponse<Response>> {
    if (nonBlockingRequest === undefined || nonBlockingRequest === false) {
        commit(AppMutationTypes.queueLoading, undefined);
    }

    try {
        const response = await action();
        const data = response.data;

        if (mutationType && !response.errors) {
            const payload = mutationPayloadFactory
                ? mutationPayloadFactory(data)
                : data;
            commit(mutationType, payload);
        }

        commit(AppMutationTypes.dequeueLoading, undefined);

        return response;
    } catch (error) {
        const axiosError: AxiosError = error as AxiosError;
        if (
            axiosError &&
            axiosError.response &&
            axiosError.response.status === 404
        ) {
            // set the error in the state
            commit(
                AppMutationTypes.setError,
                "The requested item could not be found."
            );
            commit(AppMutationTypes.dequeueLoading, undefined);
        }

        throw error;
    }
}
