const options: Intl.NumberFormatOptions = {
    style: "currency",
    currency: "USD"
};

/**
 * Formats a number as currency
 * @param value the value to format
 * @returns the formatted currency
 */
export function formatCurrency(value: number | null | undefined): string {
    // allow for 0
    if (value === null || value === undefined || isNaN(value)) {
        return "";
    }

    if (typeof value === "string") {
        value = parseFloat(value);
    }

    if (isNaN(value)) {
        return "";
    }

    return value.toLocaleString(undefined, options);
}
