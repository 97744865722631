export const routeNames = {
    reportCards: "Report Cards",
    home: "Home",
    courses: "My Products",
    course: "My Product",
    lesson: "Lesson",
    students: "My Students",
    quiz: "Quiz",
    faq: "FAQs",
    profile: "My Profile",
    collection: "My Collection",
    community: "Community & Help",
    account: "Manage My Account",
    subscription: "Manage Subscription",
    library: "eSpired Library",
    cancellationOffer: "Cancellation Offer"
};
