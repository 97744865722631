import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "btn font-weight-bold text-success ps-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
    _createVNode(_component_font_awesome_icon, { icon: _ctx.icon }, null, 8, ["icon"])
  ]))
}