import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "content-card" }
const _hoisted_2 = {
  key: 0,
  class: "content-card-border-top gradient-primary"
}
const _hoisted_3 = { class: "content-card-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.useDefaultTheme)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "content-card-border-top",
          style: _normalizeStyle({
                background: `linear-gradient(to right, ${_ctx.borderColorHex}, ${_ctx.borderColorHex}80)`
            })
        }, null, 4)),
    _renderSlot(_ctx.$slots, "header"),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "title"),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _renderSlot(_ctx.$slots, "footer")
  ]))
}