import { ThemeCard, ThemeEnum } from "@/models";

export function getThemes(selectedTheme?: ThemeEnum): ThemeCard[] {
    return [
        {
            icon: "cloud",
            type: "Sky",
            selected: selectedTheme === "Sky"
        },
        {
            icon: "mountain",
            type: "Mountain",
            selected: selectedTheme === "Mountain"
        },
        {
            icon: "cloud-sun",
            type: "Sunset",
            selected: selectedTheme === "Sunset"
        }
    ];
}
