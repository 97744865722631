
import { defineComponent, computed, ref } from "vue";
import { useStore } from "@/store";
import { menuItems, library } from "./menu";
import NavigationLinkContent from "./NavigationLinkContent.vue";
import NavigationLink from "./NavigationLink.vue";
import { loadUserDependentData } from "@/helpers";
import { MenuGroup } from "@/models";
import { MenuItem } from "@/models/MenuItem";
import { event } from "vue-gtag";

export default defineComponent({
    name: "navigation-links",
    components: {
        "navigation-link": NavigationLink,
        "navigation-link-content": NavigationLinkContent
    },
    emits: ["clicked"],
    setup() {
        const store = useStore();
        const userLoading = ref(true);
        const isParent = computed(() => store.getters.isParent);

        const groups = computed(() => {
            // creates an array of arrays for every key in MenuGroup
            let groups = Array(0);
            // divided by 2 because enums have the keys and values as keys which doubles the length
            for (let i = 0; i < Object.keys(MenuGroup).length / 2; i++) {
                groups.push([]);
            }

            menuItems.forEach((x) => {
                // determine if the user has access to the links
                if (
                    (!x.forParent && !x.forStudent) ||
                    (x.forParent && isParent.value) ||
                    (x.forStudent && !isParent.value)
                ) {
                    // determine if the item is not the eSpired library option (regular process)
                    if (!x.isLibrary) {
                        groups[x.group ?? 0].push(x);
                    } else if (
                        store.state.user?.role === "Parent" ||
                        (store.state.user?.role === "Student" &&
                            store.state.user?.hasLibrary)
                    ) {
                        groups[x.group ?? 0].push(x);
                    }
                }
            });
            return groups;
        });

        const onItemClick = (menuItem: MenuItem): void => {
            switch (menuItem.name) {
                case library.name:
                    if (!store.state.user?.hasLibrary) {
                        // Update Google Analytics event in click handler
                        event("lms_espired_library_purchase");
                    } else {
                        event("lms_espired_library_access");
                    }
                    break;
            }
        };

        loadUserDependentData(store, () => {
            userLoading.value = false;

            if (!store.state.user) {
                return;
            }
            const user = store.state.user;

            if (user.hasLibrary || store.getters.isParent) {
                library.href = user.libraryUrl ?? "";
            }

            // show link for all parents and students if they have access to the library
            if (store.getters.isParent) {
                library.forParent = true;
            } else if (user.hasLibrary) {
                library.forStudent = true;
            }
        });

        return {
            groups,
            isParent,
            onItemClick,
            userLoading
        };
    }
});
