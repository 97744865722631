/**
 * Formats a date
 * @param date the date
 * @returns the formatted date
 */
export function formatDate(date: Date | string | undefined | null): string {
    if (!date) {
        return "";
    }

    if (typeof date === "string") {
        date = new Date(date);
    }

    return date.toLocaleDateString();
}
