
import { defineComponent, onMounted, ref } from "vue";
import NavigationLinks from "@/components/layout/NavigationLinks.vue";
import UserInfo from "@/components/layout/UserInfo.vue";
import { Offcanvas } from "bootstrap";

export default defineComponent({
    components: {
        "user-info": UserInfo,
        "navigation-links": NavigationLinks
    },
    setup() {
        const offcanvas = ref(null);
        const bsOffcanvas = ref<Offcanvas | null>(null);

        onMounted(() => {
            if (offcanvas.value) {
                bsOffcanvas.value = new Offcanvas(offcanvas.value);
            }
        });

        const handleClick = (): void => {
            if (!bsOffcanvas.value) {
                return;
            }

            bsOffcanvas.value.hide();
        };

        return { handleClick, offcanvas };
    }
});
