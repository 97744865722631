import { ProficiencyStatusEnum } from "@/models";

export function getProficiencyClassName(
    proficiencyStatus: ProficiencyStatusEnum
): string {
    switch (proficiencyStatus) {
        case "Fail":
            return "text-danger";
        case "Perfect":
            return "text-success";
        case "NotAttempted":
            return "text-blue";
        case "PassedGreen":
            return "text-success";
        case "PassedYellow":
            return "text-warning";
    }
}
