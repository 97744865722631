import { OptionModel, StudentCourseProgressModel } from "@/models";

/**
 * Creates a select list of courses to use for the course selection control in the template
 */
export function mapStudentCourseOptions(
    courses?: StudentCourseProgressModel[] | null
): OptionModel[] {
    return (
        courses?.map((course) => ({
            text: course.name || "",
            value: course.courseId?.toString() || ""
        })) || []
    );
}
