import { App } from "vue";

import CancelButton from "./account/CancelButton.vue";
import ContentCard from "./ContentCard.vue";
import EditButton from "./account/EditButton.vue";
import LoadingIndicator from "./LoadingIndicator.vue";
import SaveButton from "./account/SaveButton.vue";
import { useFontAwesome } from "./useFontAwesome";

export function addSharedComponents(app: App): void {
    useFontAwesome(app);
    app.component("loading-indicator", LoadingIndicator);
    app.component("edit-button", EditButton);
    app.component("save-button", SaveButton);
    app.component("cancel-button", CancelButton);
    app.component("content-card", ContentCard);
}
