import { RouteComponent, RouteLocationNormalized, RouteRecordRaw } from "vue-router";

import { Breadcrumb, LmsRoleEnum } from "@/models";

/**
 * Creates a route record.
 * @param path route path
 * @param name route name (also used as the title)
 * @param viewName view component name
 * @param breadcrumbs routes to be displayed in breadcrumbs
 * @param props route props
 * @param role required role, if omitted, any role is allowed
 * @returns a route record
 */
export function createRoute(
    path: string,
    name: string,
    viewName: string,
    breadcrumbs: Breadcrumb[] = [],
    props?: (to: RouteLocationNormalized) => Record<string, unknown>,
    role?: LmsRoleEnum
): RouteRecordRaw {
    return {
        path,
        name,
        component: (): Promise<RouteComponent> =>
            import(`@/views/${viewName}.vue`),
        props,
        meta: {
            title: name,
            breadcrumbs: breadcrumbs,
            role: role
        }
    };
}
