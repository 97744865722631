import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9fe495b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "d-flex flex-wrap align-items-center justify-content-center py-3 my-4 border-top" }
const _hoisted_3 = { class: "nav justify-content-center" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "nav-link px-2 text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("footer", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem) => {
          return (_openBlock(), _createElementBlock("li", {
            key: menuItem.name,
            class: "nav-item"
          }, [
            _createElementVNode("a", {
              href: menuItem.href,
              class: "nav-link px-2 text-muted",
              target: "_blank"
            }, _toDisplayString(menuItem.name), 9, _hoisted_4)
          ]))
        }), 128)),
        _createElementVNode("li", null, [
          _createElementVNode("span", _hoisted_5, "v" + _toDisplayString(_ctx.version), 1)
        ])
      ])
    ])
  ]))
}