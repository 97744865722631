
import { computed, defineComponent } from "vue";
import { msalService } from "@/authentication";
import { useStore } from "@/store";

import ProfileImageVue from "@/components/ProfileImage.vue";

export default defineComponent({
    name: "user-info",
    components: {
        "profile-image": ProfileImageVue
    },
    setup() {
        const { state, getters } = useStore();

        return {
            fullName: computed(() => getters.fullName),
            familyName: computed(() => state.user?.familyName),
            profileImageSource: computed(
                () => state.user?.profileImageUrl || undefined
            ),
            signOut: (): Promise<void> => msalService.signOut()
            // uncomment to test refresh token
            // refreshToken: (): Promise<string | null> =>
            //     msalService.refreshToken(getters.signInPolicy),
            // triggerApiError: (): Promise<unknown> =>
            //     restClient.getJson("/api/admin/CancellationBlackoutDate/1234")
        };
    }
});
