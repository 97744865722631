import { createStore, useStore as baseUseStore } from "vuex";

import { API_BASE_URL, APP_VERSION, CDN_BASE_URL } from "@/configuration";

import { actions } from "./actions";
import { AppState } from "./AppState";
import { AppStore } from "./AppStore";
import { getters } from "./getters";
import { mutations } from "./mutations";

const initialState: AppState = {
    user: null,
    accessToken: null,
    settings: null,
    apiBaseUrl: API_BASE_URL,

    sidebarIsCollapsed: false,

    loadingQueue: [],
    stickers: [],
    courseStickers: [],
    rank: null,

    students: [],
    error: null,
    version: APP_VERSION,

    pageTitle: null,
    pageImageUrl: null,

    faqSections: require("@/assets/json/frequently-asked-questions.json"),
    courseItems: [],
    recentCourses: [],
    lessonItems: [],
    lessons: [],
    states: require("@/assets/json/states.json"),

    subscriptions: [],
    subscriptionDetails: {},

    communityImages: [],

    cdnBaseUrl: CDN_BASE_URL
};

/**
 * strongly typed useStore - see https://vuex.vuejs.org/guide/typescript-support.html#typing-usestore-composition-function
 */
export const storeKey = Symbol();

export function useStore(): AppStore {
    return baseUseStore(storeKey) as unknown as AppStore;
}

export const store = createStore<AppState>({
    state: initialState,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {}
});
