import { OptionModel, SelectListItem } from "@/models";

/**
 * Maps the given select list items to options models.
 * @param items select list items
 * @returns options models
 */
export function mapSelectListItemOptions(
    items?: SelectListItem[] | null
): OptionModel[] {
    if (!items) {
        return [];
    }

    return items.map((item) => ({
        text: item.text ?? "",
        value: item.value ?? ""
    }));
}
