
import { defineComponent } from "vue";
import { CDN_BASE_URL } from "@/configuration";
import LogoFooter from "@/components/LogoFooter.vue";

import {
    msalService,
    parentSignUpSignInPolicy,
    studentSignUpSignInPolicy
} from "@/authentication";

export default defineComponent({
    name: "sign-in",
    components: {
        "logo-footer": LogoFooter
    },
    setup() {
        return {
            imagesUrl: CDN_BASE_URL + "/assets/images",
            signInParent(): Promise<void> {
                return msalService.signIn(parentSignUpSignInPolicy);
            },
            signInStudent(): Promise<void> {
                return msalService.signIn(studentSignUpSignInPolicy);
            }
        };
    }
});
