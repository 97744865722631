import { Store } from "vuex";

import { AppState } from "@/store";
import { UserModel } from "@/models";

/**
 * Calls the given function after we have the user.
 * @param store the store
 * @param callback the function to call we have the user
 */
export function loadUserDependentData(
    store: Store<AppState>,
    callback: () => void
): void {
    if (store.state.user) {
        callback();
        return;
    }

    // wait for the access token to be set before executing the given callback
    store.watch(
        (state) => state.user,
        (user: UserModel | null) => {
            if (user) {
                callback();
            }
        }
    );
}
