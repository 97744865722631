
import { defineComponent } from "vue";

export default defineComponent({
    name: "save-button",
    props: {
        submitting: {
            type: Boolean,
            required: false,
            default: false
        },
        text: {
            type: String,
            required: false,
            default: "Save"
        },
        icon: {
            type: String,
            required: false,
            default: "save"
        },
        isCancelPopUp: {
            type: Boolean,
            required: false,
            default: false
        }
    }
});
