import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row justify-content-center flex" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logos, (logo) => {
        return (_openBlock(), _createElementBlock("div", {
          key: logo.link,
          class: "col-sm-3 col-4"
        }, [
          _createElementVNode("a", {
            href: logo.link,
            target: "_blank"
          }, [
            _createElementVNode("img", {
              src: `${_ctx.imagesUrl}${logo.source}`,
              class: "img-fluid",
              alt: logo.title
            }, null, 8, _hoisted_4)
          ], 8, _hoisted_3)
        ]))
      }), 128))
    ])
  ]))
}