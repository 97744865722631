
import { computed, defineComponent, onMounted } from "vue";
import NavigationLinks from "@/components/layout/NavigationLinks.vue";
import UserInfo from "@/components/layout/UserInfo.vue";
import { msalService } from "@/authentication";
import { useStore } from "@/store";

export default defineComponent({
    name: "sidebar-navigation",
    components: {
        "user-info": UserInfo,
        "navigation-links": NavigationLinks
    },
    setup() {
        onMounted(async () => {
            // try to get the account from MSAL/localStorage
            await msalService.getAccount();
        });

        const { getters } = useStore();

        return {
            isAuthorized: computed(
                () => getters.signedIn && getters.isAuthorized
            )
        };
    }
});
