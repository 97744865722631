import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d31c07a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar-wrapper flex-column justify-content-between min-vh-100 d-md-flex d-none" }
const _hoisted_2 = { id: "sidebar-heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_info = _resolveComponent("user-info")!
  const _component_navigation_links = _resolveComponent("navigation-links")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_user_info)
      ]),
      (_ctx.isAuthorized)
        ? (_openBlock(), _createBlock(_component_navigation_links, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ]))
}