import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pl-5 d-flex align-items-center" }
const _hoisted_2 = { class: "fa-stack fa-lg" }
const _hoisted_3 = { class: "mb-0 ms-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_font_awesome_icon, {
        icon: "circle",
        class: "fa-stack-2x"
      }),
      _createVNode(_component_font_awesome_icon, {
        icon: _ctx.menuItem.icon,
        class: "fa-stack-1x icon-top"
      }, null, 8, ["icon"])
    ]),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.menuItem.name), 1)
  ]))
}