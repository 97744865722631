import {
    AAD_CACHE_LOCATION, AAD_CLIENT_ID, AAD_KNOWN_AUTHORITIES, AAD_LOGOUT_REDIRECT_URI,
    AAD_REDIRECT_URI, AAD_STUDENT_SIGN_IN_AUTHORITY
} from "@/configuration";
import { Configuration } from "@azure/msal-browser";

export const msalConfig: Configuration = {
    auth: {
        authority: AAD_STUDENT_SIGN_IN_AUTHORITY,
        clientId: AAD_CLIENT_ID,
        redirectUri: AAD_REDIRECT_URI,
        knownAuthorities: AAD_KNOWN_AUTHORITIES,
        postLogoutRedirectUri: AAD_LOGOUT_REDIRECT_URI
    },
    cache: { cacheLocation: AAD_CACHE_LOCATION }
};
